<template>
  <div class="examine_questionnaire_container">
    <div class="title">新冠肺炎流行病学史调查问卷</div>
    <div class="template">
      <div class="question info">
        <div>
          <span class="require">*</span>
          <span class="tit">1、姓名</span>
        </div>
        <input name="name"
               readonly
               disabled
               value="info.name" />
      </div>
      <div class="question info">
        <div>
          <span class="require">*</span>
          <span class="tit">2、联系电话</span>
        </div>
        <input name="phone"
               readonly
               value="info.telephone"
               disabled />
      </div>
      <div class="question info">
        <div>
          <span class="require">*</span>
          <span class="tit">3、身份证号码</span>
        </div>
        <input name="IDCard"
               readonly
               disabled
               value="info.cardNo" />
      </div>
      <form @submit.prevent="formSubmit"
            autocomplete="off">
        <div class="question">
          <div>
            <span class="require">*</span>
            <span class="tit">4、您21天内是否有发热(体温≥37.3℃)?</span>
          </div>
          <div class="option">
            <label style="width:30%;">
              <input type="radio"
                     name="question1"
                     value="否:1" />
              <span>否</span>
            </label>
            <label style="width:30%;"
                   @click="warningClick">
              <input type="radio"
                     name="question1"
                     value="是:0" />
              <span>是</span>
            </label>
            <div class="input">
              <span>体温：</span>
              <input name="question1Input"
                     type="digit"
                     style="width:40%;height:100%" />
            </div>
          </div>
        </div>
        <div class="question">
          <span class="require">*</span>
          <span class="tit">5、您是否有咳嗽、气促等呼吸道症状?</span>
          <div class="option">
            <label style="width:20%">
              <input type="radio"
                     name="question2"
                     value="否:1" />
              <span>否</span>
            </label>
            <label style="width:20%"
                   @click="warningClick">
              <input type="radio"
                     name="question2"
                     value="是:0" />
              <span>是</span>
            </label>
          </div>
        </div>
        <div class="question">
          <span class="require">*</span>
          <span class="tit">6、21天内您或共同居住的家庭有到过以下地方旅居室吗?</span>
          <div class="option">
            <label>
              <input type="radio"
                     name="question3"
                     value="一直在广东省内:0" />
              <span>一直在广东省内</span>
            </label>
          </div>
          <div class="option">
            <label @click="warningClick">
              <input type="radio"
                     name="question3"
                     value="国内高中风险地区:1" />
              <span>国内高中风险地区</span>
            </label>
          </div>
          <div class="option">
            <label @click="warningClick">
              <input type="radio"
                     name="question3"
                     value="其他有病例报告社区:2" />
              <span>其他有病例报告社区</span>
            </label>
          </div>
          <div class="option">
            <label style="width:75%"
                   @click="warningClick">
              <input type="radio"
                     name="question3"
                     value="国内其他地区:3" />
              <span>国内其他地区</span>
            </label>
            <div class="input">
              <input name="question3Input1"
                     style="width:100%;height:100%" />
            </div>
          </div>
          <div class="option">
            <label style="width:100%;"
                   @click="warningClick">
              <input type="radio"
                     name="question3"
                     value="境外:4" />
              <span>境外(除澳门以外)</span>
            </label>
            <div class="input">
              <input name="question3Input2"
                     style="width:100%;height:100%" />
            </div>
          </div>
        </div>
        <div class="question">
          <span class="require">*</span>
          <span class="tit">7、21天内您有共同居住的家属是否接触新冠肺炎确诊病例、疑似病例或无症状感染者吗?</span>
          <div class="option">
            <label style="width:20%">
              <input type="radio"
                     name="question4"
                     value="否:1" />
              <span>否</span>
            </label>
            <label style="width:20%"
                   @click="warningClick">
              <input type="radio"
                     name="question4"
                     value="是:0" />
              <span>是</span>
            </label>
          </div>
        </div>
        <div class="question">
          <span class="require">*</span>
          <span class="tit">8、21天内您或共同居住的家属是否接触来自境外（含港澳台）或国内高中风险地区的发热或有呼吸道症状的患者吗？</span>
          <div class="option">
            <label style="width:20%">
              <input type="radio"
                     name="question5"
                     value="否:1" />
              <span>否</span>
            </label>
            <label style="width:20%"
                   @click="warningClick">
              <input type="radio"
                     name="question5"
                     value="是:0" />
              <span>是</span>
            </label>
          </div>
        </div>
        <div class="question">
          <span class="require">*</span>
          <span class="tit">9、21天内您所处家庭或办公室等小范围内是否出现2例及以上发热和/或呼吸道症状的病例?</span>
          <div class="option">
            <label style="width:20%">
              <input type="radio"
                     name="question6"
                     value="否:1" />
              <span>否</span>
            </label>
            <label style="width:20%"
                   @click="warningClick">
              <input type="radio"
                     name="question6"
                     value="是:0" />
              <span>是</span>
            </label>
          </div>
        </div>
        <div class="question">
          <span class="require">*</span>
          <span class="tit">10、21天内您是否曾在集中隔离医学观察场所留观?</span>
          <div class="option">
            <label style="width:20%">
              <input type="radio"
                     name="question7"
                     value="否:1" />
              <span>否</span>
            </label>
            <label style="width:20%"
                   @click="warningClick">
              <input type="radio"
                     name="question7"
                     value="是:0" />
              <span>是</span>
            </label>
          </div>
        </div>
        <div class="question">
          <span class="require">*</span>
          <span class="tit">本人保证，以上内容真实无误，如有瞒报，将承担法律责任。</span>
        </div>
        <input type="submit"
               value="提   交"
               class="submit">
      </form>
    </div>
  </div>
</template>
<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
export default {
  name: "Questionnaire",
  data () {
    return {};
  },
  created () { },
  methods: {
    warningClick () {
      this.$dialog
        .confirm({
          title: "提示",
          message: "请确保所填内容准确无误"
        })
        .then(() => {
          this.$toast.fail("请去发热门诊找医护人员指导就诊");
        })
        .catch(() => { });
    },
    formSubmit (event) {
      // 第一题
      let obj1 = {};
      let flag1 = true;
      obj1.questionName = "您21天内是否有发热（体温≥37.3℃）?";
      obj1.orderNo = 1;
      // 第二题
      let obj2 = {};
      let flag2 = false;
      obj2.questionName = "您是否有咳嗽、气促等呼吸道症状?";
      obj2.orderNo = 2;
      // 第三题
      let obj3 = {};
      let flag3 = false;
      obj3.questionName = "21天内您或共同居住的家庭有到过以下地方旅居室吗?";
      obj3.orderNo = 3;
      // 第四题
      let obj4 = {};
      let flag4 = false;
      obj4.questionName =
        "21天内您有共同居住的家属是否接触新冠肺炎确诊病例、疑似病例或无症状感染者吗?";
      obj4.orderNo = 4;
      // 第五题
      let obj5 = {};
      let flag5 = false;
      obj5.questionName =
        "21天内您或共同居住的家属是否接触来自境外（含港澳台）或国内高中风险地区的发热或有呼吸道症状的患者吗？";
      obj5.orderNo = 5;
      // 第六题
      let obj6 = {};
      let flag6 = false;
      obj6.questionName =
        "21天内您所处家庭或办公室等小范围内是否出现2例及以上发热和/或呼吸道症状的病例?";
      obj6.orderNo = 6;
      // 第七题
      let obj7 = {};
      let flag7 = false;
      obj7.questionName = "21天内您是否曾在集中隔离医学观察场所留观?";
      obj7.orderNo = 7;
      //整理数据
      let flag8 = false;
      let obj = {};
      let formData = new FormData(event.target);
      for (let [a, b] of formData) {
        obj[a] = b;
      }
      //第4题
      if (flag1) {
        if (!obj.question1) {
          this.$toast.fail("请完成题目4");
        } else {
          if (parseInt(obj.question1.split(":")[1]) === 0) {
            if (obj.question1Input === "") {
              this.$toast.fail("请填写体温");
            } else {
              obj1.answerValue =
                obj.question1.split(":")[0] + "$$" + obj.question1Input;
              obj1.riskMarker = 0;
              flag2 = true;
            }
          } else {
            obj1.answerValue = obj.question1.split(":")[0];
            obj1.riskMarker = 1;
            flag2 = true;
          }
        }
      }
      //第5题
      if (flag2) {
        if (!obj.question2) {
          this.$toast.fail("请完成题目5");
        } else {
          if (obj.question2.split(":")[1] === 0) {
            obj2.riskMarker = 0;
          } else {
            obj2.riskMarker = 1;
          }
          obj2.answerValue = obj.question2.split(":")[0];
          flag3 = true;
        }
      }
      //第6题
      if (flag3) {
        if (!obj.question3) {
          this.$toast.fail("请完成题目6");
        } else {
          if (parseInt(obj.question3.split(":")[1]) === 0) {
            obj3.answerValue = obj.question3.split(":")[0];
            obj3.riskMarker = 1;
            flag4 = true;
          } else if (parseInt(obj.question3.split(":")[1]) === 1) {
            obj3.answerValue = obj.question3.split(":")[0];
            obj3.riskMarker = 0;
            flag4 = true;
          } else if (parseInt(obj.question3.split(":")[1]) === 2) {
            obj3.answerValue = obj.question3.split(":")[0];
            obj3.riskMarker = 0;
            flag4 = true;
          } else if (parseInt(obj.question3.split(":")[1]) === 3) {
            if (obj.question3Input1 === "") {
              this.$toast.fail("请填写国内其他地区");
            } else {
              obj3.answerValue =
                obj.question3.split(":")[0] + "$$" + obj.question3Input1;
              obj3.riskMarker = 1;
              flag4 = true;
            }
          } else if (parseInt(obj.question3.split(":")[1]) === 4) {
            if (obj.question3Input2 === "") {
              this.$toast.fail("请填写境外");
            } else {
              obj3.answerValue =
                obj.question3.split(":")[0] + "$$" + obj.question3Input2;
              obj3.riskMarker = 0;
              flag4 = true;
            }
          }
        }
      }
      //第7题
      if (flag4) {
        if (!obj.question4) {
          this.$toast.fail("请完成题目7");
        } else {
          if (parseInt(obj.question4.split(":")[1]) === 0) {
            obj4.riskMarker = 0;
          } else {
            obj4.riskMarker = 1;
          }
          obj4.answerValue = obj.question4.split(":")[0];
          flag5 = true;
        }
      }
      //第8题
      if (flag5) {
        if (!obj.question5) {
          this.$toast.fail("请完成题目8");
        } else {
          if (parseInt(obj.question5.split(":")[1]) === 0) {
            obj5.riskMarker = 0;
          } else {
            obj5.riskMarker = 1;
          }
          obj5.answerValue = obj.question5.split(":")[0];
          flag6 = true;
        }
      }
      //第9题
      if (flag6) {
        if (!obj.question6) {
          this.$toast.fail("请完成题目9");
        } else {
          if (parseInt(obj.question6.split(":")[1]) === 0) {
            obj6.riskMarker = 0;
          } else {
            obj6.riskMarker = 1;
          }
          obj6.answerValue = obj.question6.split(":")[0];
          flag7 = true;
        }
      }
      //第10题
      if (flag7) {
        if (!obj.question7) {
          this.$toast.fail("请完成题目10");
        } else {
          if (parseInt(obj.question7.split(":")[1]) === 0) {
            obj7.riskMarker = 0;
          } else {
            obj7.riskMarker = 1;
          }
          obj7.answerValue = obj.question7.split(":")[0];
          flag8 = true;
        }
      }
      //整理数据
      if (flag8) {
        let arr = [];
        arr.push(obj1);
        arr.push(obj2);
        arr.push(obj3);
        arr.push(obj4);
        arr.push(obj5);
        arr.push(obj6);
        arr.push(obj7);
        for (let i = 0; i < arr.length; i++) {
          arr[i].appType = 1;
          arr[i].cardNo = "123456";
          arr[i].openId = window.localStorage.getItem("openId");
          arr[i].patCardNo = "123456";
          arr[i].telephone = "123456";
          arr[i].patientName = "123456";
          arr[i].appointmentTime = "123456";
          arr[i].questionnaireCode = "1001";
          arr[i].questionnaireTitle = "新冠肺炎流行病学史调查问卷";
        }
        let flag = false;
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].riskMarker === 0) {
            flag = true;
          }
        }
        if (flag) {
          this.$toast.fail("请去发热门诊找医护人员指导就诊");
        } else {
          this.$router.push({
            path: "/examine/order"
          });
        }
      }
    }
  }
};
</script>
<style scope>
.examine_questionnaire_container .title {
  text-align: center;
  font-size: 40px;
  padding-top: 30px;
}
.examine_questionnaire_container .template {
  padding: 0 50px;
  font-size: 36px;
  padding-bottom: 180px;
}
.examine_questionnaire_container .question {
  position: relative;
  margin-top: 30px;
}
.examine_questionnaire_container .question .require {
  position: absolute;
  left: -34px;
  top: 0;
  color: red;
  font-size: 64px;
}
.examine_questionnaire_container .info {
  display: flex;
}
.examine_questionnaire_container .info div {
  width: 40%;
}
.examine_questionnaire_container .info input {
  width: 60%;
  padding-left: 10px;
}
.examine_questionnaire_container .question .option {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.examine_questionnaire_container .question .option label {
  display: flex;
  align-items: center;
}
.examine_questionnaire_container .question .option span {
  margin-left: 10px;
}
.examine_questionnaire_container .question .option .input {
  transform: translateX(-20px);
}
.examine_questionnaire_container .question .option .input input {
  padding-left: 10px;
}
.template .submit {
  position: fixed;
  bottom: 60px;
  left: 0;
  width: 100%;
  height: 100px;
  background-color: #1989fa;
  color: white;
  border: unset;
  font-size: 40px;
}
input[type='radio'] {
  width: 40px !important;
  height: 40px !important;
}
input[type='radio']:after {
  width: 40px !important;
  height: 40px !important;
}
</style>